import React from 'react';
import styled from 'styled-components';
import StyledButton from './StyledButton';

export default function RedButton({
  onClick,
  disabled,
  text,
  size,
}) {
  return (
    <StyledDiv>
      <StyledButton text={text} disabled={disabled} size={size} onClick={() => onClick()} />
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  .btn-primary {
    background-color: #bb0000;
  }
  .btn-primary:hover {
    background: #9e0404;
  }
  .btn-primary:focus {
    background: #9e0404;
  }

  .btn-primary:disabled {
    background: gray;
  }
`;
