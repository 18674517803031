import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import FontAwesome from 'react-fontawesome';
import VioletButton from '../buttons/VioletButton';
import { getCollectionById } from '../../models/collections';
import { getDurationByValue } from '../../models/durations';

export default function ConfirmStakeDialogue({
  handleClose,
  handleConfirm,
  selectedNfts,
  selectedCollection,
}) {
  const [selectedDuration, setSelectedDuration] = useState('');
  const [estimated, setEstimated] = useState('');

  const getReward = (value) => {
    const { rewards } = getCollectionById(selectedCollection);
    const amount = rewards[value];
    const reward = selectedNfts.length * amount;
    return +reward.toFixed(2);
  };

  const getDuration = (value) => {
    const duration = getDurationByValue(value).time;
    return duration;
  };

  const setEstimatedBeets = (value) => {
    const duration = getDuration(value);
    setSelectedDuration(duration);
    setEstimated(getReward(value));
  };

  useEffect(() => {
    setEstimatedBeets('daily');
  }, []);

  return (
    <ModalWrapper>
      <Container>
        <div className='modal-img'>
          {selectedNfts.map((nft) => (
            nft.isVideo ? (
              <video autoPlay muted key={nft.tokenId}>
                <source src={nft.imageUrl} type='video/mp4' />
              </video>
            ) : <img src={nft.imageUrl} alt={nft.tokenId} key={nft.tokenId} />
          ))}
        </div>

        <div className='modal-content'>
          <div className='header'>
            <h3 className='title'>
              {selectedNfts.length} {selectedNfts.length > 1 ? 'NFTs' : 'NFT'}{' '}
              selected
            </h3>
          </div>

          <p className='estimated-wrapper'>
            <span className='label'>Estimated Daily Reward: </span>
            <span className='estimated-amount'>{estimated} BEETS</span>
          </p>
          <VioletButton
            text='Confirm'
            onClick={() => handleConfirm(selectedDuration)}
          />
        </div>

        <button
          className='close-btn'
          type='button'
          onClick={() => handleClose()}
        >
          <FontAwesome name='close' size='lg' />
        </button>
      </Container>
    </ModalWrapper>
  );
}

const ModalWrapper = styled(Container)`
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  top: 0px;

  .container {
    position: relative;
    display: flex;
    background: #efebe5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 30px;
    column-gap: 20px;

    @media only screen and (max-width: 576px) {
      flex-direction: column;
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      
      .header {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        margin-bottom: 15px;

        .title {
          margin: 0px;
        }

        .sub-title {
          margin: 0px;
          font-size: 0.8rem;
          font-weight: 800;
        }
      }

      .duration-selector {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        margin: 10px 0px;
      }

      .duration-selector select {
        /* styling */
        background-color: white;
        border-radius: 4px;
        display: inline-block;
        font: inherit;
        line-height: 1.5em;
        padding: 0.3em 3.5em 0.3em 1em;

        /* reset */

        margin: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;

        background-image: linear-gradient(45deg, transparent 50%, gray 50%),
          linear-gradient(135deg, gray 50%, transparent 50%),
          linear-gradient(to right, #ccc, #ccc);
        background-position: calc(100% - 20px) calc(1em + 2px),
          calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
        background-size: 5px 5px, 5px 5px, 1px 1.5em;
        background-repeat: no-repeat;
      }
    }

    .modal-img {
      width: 210px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      height: 210px;
      overflow-y: auto;
      padding-right: 5px;

      img {
        width: 100%;
        border-radius: 8px;
      }
    }

    .modal-img::-webkit-scrollbar {
      width: 4px;
    }

    .modal-img::-webkit-scrollbar-track {
      background-color: #c4c4c4;
    }

    .modal-img::-webkit-scrollbar-thumb {
      background-color: #a1a0a0;
      border-radius: 8px;
    }

    .close-btn {
      position: absolute;
      right: 10px;
      top: 10px;
      background: none;
      border: none;
      color: gray;
      transition: all 200ms ease-in;
    }

    .close-btn:hover {
      color: black;
    }

    .label {
      font-size: 0.8rem;
    }

    .estimated-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 5px;

      .estimated-amount {
        font-size: 1rem;
        font-weight: 800;
      }
    }
  }
`;
