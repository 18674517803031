/* eslint-disable no-unused-vars */
import { makeAutoObservable } from 'mobx';
import Web3 from 'web3';

export default class CollectionStore {
  walletStore;

  _userAddr;

  get userAddr() {
    return this._userAddr ?? this.walletStore.userAccount;
  }

  set userAddr(value) {
    this._userAddr = value;
  }

  constructor(walletStore, contractAddr, contractJson) {
    // makeAutoObservable(this);
    this.walletStore = walletStore;
    this.contractAddr = contractAddr;
    this.contractJson = contractJson;
  }

  _canView = false;

  set canView(value) {
    this._canView = value;
  }

  get canView() {
    return this.walletStore.userAccount && this.walletStore.isWalletInstalled;
  }

  _tokens = [];

  set tokens(value) {
    this._tokens = value;
  }

  get tokens() {
    return this._tokens;
  }

  getWeb3 = (useLocalProvider = false) => {
    let web3Provider;

    if (useLocalProvider) {
      web3Provider = new Web3(window.ethereum);
    } else {
      web3Provider = new Web3.providers.HttpProvider(
        process.env.REACT_APP_BLOCKCHAIN_HTTPS_URL
      );
    }

    const web3Instance = new Web3(web3Provider);

    return web3Instance;
  };

  getContract = (contractJson, contractAddr, useLocalProvider = false) => {
    const web3Instance = this.getWeb3(useLocalProvider);

    web3Instance.eth.transactionBlockTimeout = 200;

    const contract = new web3Instance.eth.Contract(
      contractJson.abi,
      contractAddr
    );

    return contract;
  };

  getSchrootContract = (useLocalProvider = false) =>
    this.getContract(this.contractJson, this.contractAddr, useLocalProvider);

  getTokensOfOwner = async () => {
    if (this.userAddr) {
      const contractInstance = this.getSchrootContract(false);
      this.tokens = await contractInstance.methods
        .tokensOfOwner(this.userAddr)
        .call();
      return this.tokens;
    }
    return [];
  };
}
