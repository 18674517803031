import axios from 'axios';
import { getCollectionById } from './collections';
import { getDurationByTime } from './durations';

export default class NftData {
  constructor(tokenId, imageUrl, openSeaUrl, metaUrl, ipfs, collectionId, isVideo) {
    this.tokenId = tokenId;
    this.imageUrl = imageUrl;
    this.openSeaUrl = openSeaUrl;
    this.metaUrl = metaUrl;
    this.ipfs = ipfs;
    this.collectionId = collectionId;
    this.isVideo = isVideo;
  }

  static getURLParam = (url, param) => {
    const urlObj = new URL(url);
    return urlObj.searchParams.get(param);
  }

  static getDateFromUnixTimestamp = (unixTimestamp) => {
    const milliseconds = unixTimestamp * 1000; // 1575909015000
    const dateObject = new Date(milliseconds);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return dateObject.toLocaleString('en-US', { timeZone: timezone }); // 2019-12-9 10:30:15
  }

  getStakedAt = () => {
    const stakedTimeUnix = NftData.getURLParam(this.metaUrl, 'sa');
    return NftData.getDateFromUnixTimestamp(stakedTimeUnix);
  }

  getUnstakedAt = () => {
    const unstakedTimeUnix = NftData.getURLParam(this.metaUrl, 'ua');
    return NftData.getDateFromUnixTimestamp(unstakedTimeUnix);
  }

  getChoice = () => {
    const stakedTimeUnix = NftData.getURLParam(this.metaUrl, 'sa');
    const unstakedTimeUnix = NftData.getURLParam(this.metaUrl, 'ua');
    // eslint-disable-next-line radix
    const time = parseInt(unstakedTimeUnix) - parseInt(stakedTimeUnix);
    const choice = getDurationByTime(time);
    return choice.value;
  }

  getReward = () => {
    const reward = NftData.getURLParam(this.metaUrl, 'rd');
    return (reward / 1e18).toFixed(2);
  }

  static isVideo = (url) => {
    if (url.substring(url.length - 4) === '.mp4') {
      return true;
    }
    return false;
  }

  static getMetaData = async (metaUrl) =>
    axios
      .get(metaUrl)
      .then((response) => response.data)
      .catch(() =>
        Object.freeze({
          image: 'https://my-goodlife.com/img.php?imgsrc=&size=400x400',
        })
      );

  static getImageUrl = async (metaUrl, ipfs) => {
    const metaData = await this.getMetaData(metaUrl);
    const imageUrl = metaData.image;
    if (ipfs) {
      return imageUrl.replace('ipfs://', 'https://ipfs.infura.io/ipfs/');
    }
    return imageUrl;
  };

  static fromTokens = async (token, { id, baseUrl, ipfs, openSeaUrl }) => {
    const openSeaTokenUrl = openSeaUrl + token;
    const metaUrl = baseUrl + token;
    const imageUrl = await this.getImageUrl(metaUrl, ipfs);
    const collectionId = id;

    return new NftData(
      token,
      imageUrl,
      openSeaTokenUrl,
      metaUrl,
      ipfs,
      collectionId,
      this.isVideo(imageUrl)
    );
  };

  static fromMetaUrl = async (token, metaUrl, collectionId) => {
    const collection = getCollectionById(collectionId);
    const openSeaTokenUrl = null;
    const { ipfs } = collection;
    const imageUrl = await this.getImageUrl(metaUrl, ipfs);

    return new NftData(
      token,
      imageUrl,
      openSeaTokenUrl,
      metaUrl,
      ipfs,
      collectionId,
      this.isVideo(imageUrl)
    );
  };
}
