/* eslint-disable no-unreachable */
import { Biconomy } from '@biconomy/mexa';
import { makeAutoObservable } from 'mobx';
import Web3 from 'web3';
import StakingContractJSON from '../../assets/contracts/Staking.json';
import BeetsContractJSON from '../../assets/contracts/Beets.json';

import {
  getDataToSign,
  getExaggeratedLimitInHex,
  isBiconomyReady,
  signTransaction,
} from '../../utils/biconomy-utils';
import TxnState from '../../utils/txn_state';
import { parseBool } from '../../utils/type-utils';
import { getCollectionByAddress } from '../../models/collections';

export default class ContractStore {
  rootStore;

  _userAddr;

  get userAddr() {
    return this._userAddr ?? this.rootStore.walletStore.userAccount;
  }

  set userAddr(value) {
    this._userAddr = value;
  }

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  _isSaleActive = true;

  get isSaleActive() {
    return this._isSaleActive;
  }

  set isSaleActive(value) {
    this._isSaleActive = value;
  }

  _canStake = false;

  set canStake(value) {
    this._canStake = value;
  }

  get canStake() {
    return (
      this.rootStore.walletStore.userAccount &&
      this.rootStore.walletStore.isWalletInstalled
    );
  }

  getWeb3 = (useLocalProvider = false) => {
    let web3Provider;

    if (useLocalProvider) {
      web3Provider = new Web3(window.ethereum);
    } else {
      web3Provider = new Web3.providers.HttpProvider(
        process.env.REACT_APP_BLOCKCHAIN_HTTPS_URL
      );
    }

    const web3Instance = new Web3(web3Provider);

    return web3Instance;
  };

  getContract = (contractJson, contractAddr, useLocalProvider = false) => {
    const web3Instance = this.getWeb3(useLocalProvider);

    web3Instance.eth.transactionBlockTimeout = 200;

    const contract = new web3Instance.eth.Contract(
      contractJson.abi,
      contractAddr
    );

    return contract;
  };

  getStakeContract = (useLocalProvider = false) =>
    this.getContract(
      StakingContractJSON,
      process.env.REACT_APP_CONTRACT_ADDRESS_STAKE,
      useLocalProvider
    );

  getBeetsContract = (useLocalProvider = false) =>
    this.getContract(
      BeetsContractJSON,
      process.env.REACT_APP_CONTRACT_ADDRESS_BEETS,
      useLocalProvider
    );

  getInitialData = async () => {
    if (this.userAddr) {
      // const stakeContractInstance = this.getStakeContract(true);
    }
  };

  isTransactionValid = (count) => {
    let message = null;

    if (!this.isSaleActive) {
      message = `Sale is not active${count}`;
    }

    return message;
  };

  getBeetsBalance = () => {
    const contract = this.getBeetsContract(false);
    return contract.methods.balanceOf(this.userAddr).call();
  };

  getRewardinfo = (tokens) => {
    const contract = this.getStakeContract(false);
    return contract.methods.calculateTokensRewards(tokens).call();
  }

  getTokensOfOwner = (contractAddr) => {
    const contract = this.getStakeContract(false);
    return contract.methods
      .tokensOfOwnerByContract(contractAddr, this.userAddr)
      .call();
  };

  getTokenURI = (tokens) => {
    const contract = this.getStakeContract(false);
    const tokenURIs = tokens.map((tokenId) =>
      contract.methods.tokenURI(tokenId).call()
    );
    return tokenURIs;
  };

  getStakedTokens = async (contractAddr) => {
    const tokens = await this.getTokensOfOwner(contractAddr);
    const stakedTokens = await Promise.all(this.getTokenURI(tokens));
    return stakedTokens.map((e, i) =>
      Object.freeze({ tokenId: tokens[i], metaUrl: e })
    );
  };

  doStake = (stakeContractAddress, tokens, duration, handleTxn) => {
    const isGaslessMint = parseBool(process.env.REACT_APP_USE_BICONOMY);
    if (isGaslessMint) {
      this.doBiconomyStake(stakeContractAddress, tokens, duration, handleTxn);
    } else {
      this.doGassedStake(stakeContractAddress, tokens, duration, handleTxn);
    }
  };

  doUnstake = (tokens, handleTxn) => {
    const isGaslessMint = parseBool(process.env.REACT_APP_USE_BICONOMY);
    if (isGaslessMint) {
      this.doBiconomyUnstake(tokens, handleTxn);
    } else {
      this.doGassedUnstake(tokens, handleTxn);
    }
  };

  doClaimReward = (tokens, handleTxn) => {
    const isGaslessMint = parseBool(process.env.REACT_APP_USE_BICONOMY);
    if (isGaslessMint) {
      this.doBiconomyClaimReward(tokens, handleTxn);
    } else {
      this.doGassedClaimReward(tokens, handleTxn);
    }
  };

  checkApproval = async (contract) =>
    contract.methods
      .isApprovedForAll(
        this.userAddr,
        process.env.REACT_APP_CONTRACT_ADDRESS_STAKE
      )
      .call();

  setApproval = (stakeContractAddress, web3Instance, handleTxn) =>
    new Promise((resolve, reject) => {
      const { contractAddress, abi: contractJson } =
        getCollectionByAddress(stakeContractAddress);

      const contract = this.getContract(contractJson, contractAddress, true);

      this.checkApproval(contract)
        .then((isApproved) => {
          if (isApproved) {
            resolve(true);
          }
          if (!isApproved) {
            handleTxn(TxnState.Approval('pending'));
            contract.methods
              .setApprovalForAll(
                process.env.REACT_APP_CONTRACT_ADDRESS_STAKE,
                true
              )
              .estimateGas({ from: this.userAddr })
              .then((rawGaslimit) => {
                const exGasLimit = getExaggeratedLimitInHex(rawGaslimit);
                web3Instance.eth.getGasPrice().then((rawGasPrice) => {
                  const exGasPrice = getExaggeratedLimitInHex(rawGasPrice);
                  const transaction = contract.methods
                    .setApprovalForAll(
                      process.env.REACT_APP_CONTRACT_ADDRESS_STAKE,
                      false
                    )
                    .send({
                      from: this.userAddr,
                      gasLimit: exGasLimit,
                      gasPrice: exGasPrice,
                    });
                  transaction.on('error', (error, _receipt) => {
                    reject(error);
                    handleTxn(TxnState.Error(error));
                  });
                  transaction.on('receipt', () => {
                    resolve(true);
                    handleTxn(TxnState.Approval('success'));
                  });
                });
              })
              .catch((error) => {
                reject(error);
                handleTxn(TxnState.Error(error));
              });
          }
        })
        .catch((error) => {
          reject(error);
          handleTxn(TxnState.Error(error));
        });
    });

  setApprovalWithBiconomy = (stakeContractAddress, web3Instance, handleTxn) =>
    new Promise((resolve, reject) => {
      const collectionObj = getCollectionByAddress(stakeContractAddress);
      const { contractAddress, abi: contractJson } = collectionObj;

      const contract1 = this.getContract(contractJson, contractAddress, true);

      this.checkApproval(contract1)
        .then((isApproved) => {
          if (isApproved) {
            resolve(true);
          }
          if (!isApproved) {
            handleTxn(TxnState.Approval('pending'));
            this.getBiconomyInstance()
              .then((biconomyInstance) => {
                const bWeb3Instance = new Web3(biconomyInstance);
                bWeb3Instance.eth.transactionBlockTimeout = 200;
                const lWeb3Instance = this.getWeb3(true);

                const contract = new bWeb3Instance.eth.Contract(
                  contractJson.abi,
                  contractAddress
                );

                contract.methods
                  .getNonce(this.userAddr)
                  .call()
                  .then((nonce) => {
                    const fSignature = contract.methods
                      .setApprovalForAll(
                        process.env.REACT_APP_CONTRACT_ADDRESS_STAKE,
                        true
                      )
                      .encodeABI();

                    const dataToSign = getDataToSign(
                      this.userAddr,
                      nonce,
                      fSignature,
                      collectionObj.oldBicoMethod,
                      collectionObj
                    );

                    signTransaction(this.userAddr, dataToSign)
                      .then(({ r, s, v }) => {
                        contract.methods
                          .executeMetaTransaction(
                            this.userAddr,
                            fSignature,
                            r,
                            s,
                            v
                          )
                          .estimateGas({ from: this.userAddr })
                          .then((rawGasLimit) => {
                            const exGasLimit =
                              getExaggeratedLimitInHex(rawGasLimit);

                            lWeb3Instance.eth
                              .getGasPrice()
                              .then((rawGasPrice) => {
                                const exGasPrice =
                                  getExaggeratedLimitInHex(rawGasPrice);

                                const txn = contract.methods
                                  .executeMetaTransaction(
                                    this.userAddr,
                                    fSignature,
                                    r,
                                    s,
                                    v
                                  )
                                  .send({
                                    from: this.userAddr,
                                    gasLimit: exGasLimit,
                                    gasPrice: exGasPrice,
                                  });

                                txn
                                  .on('sending', () => {
                                    handleTxn(TxnState.Approval('pending'));
                                  })
                                  .on('error', (err, _receipt) => {
                                    handleTxn(TxnState.Error(err));
                                    reject(err);
                                  })
                                  .on('transactionHash', () => {
                                    // handleTxn(TxnState.Pending(txnHash));
                                  })
                                  .on('receipt', (receipt) => {
                                    handleTxn(TxnState.Approval('success'));
                                    resolve(receipt);
                                  });
                              });
                          })
                          .catch((error) => {
                            handleTxn(TxnState.Error(error));
                            reject(error);
                          });
                      })
                      .catch((error) => {
                        handleTxn(TxnState.Error(error));
                        reject(error);
                      });
                  })
                  .catch((error) => {
                    handleTxn(TxnState.Error(error));
                    reject(error);
                  });
              })
              .catch((error) => {
                handleTxn(TxnState.Error(error));
                reject(error);
              });
          }
        })
        .catch((error) => {
          reject(error);
          handleTxn(TxnState.Error(error));
        });
    });

  doGassedStake = (stakeContractAddress, tokens, duration, handleTxn) => {
    if (this.userAddr) {
      const lWeb3Instance = this.getWeb3(true);

      // const message = this.isTransactionValid(10);
      const message = null;
      if (!message) {
        this.setApproval(stakeContractAddress, lWeb3Instance, handleTxn)
          .then(() => {
            const contract = this.getStakeContract(true);
            contract.methods
              .stakeBatch(stakeContractAddress, tokens, duration)
              .estimateGas({ from: this.userAddr })
              .then((rawGaslimit) => {
                const exGasLimit = getExaggeratedLimitInHex(rawGaslimit);

                lWeb3Instance.eth.getGasPrice().then((rawGasPrice) => {
                  const exGasPrice = getExaggeratedLimitInHex(rawGasPrice);

                  const transaction = contract.methods
                    .stakeBatch(stakeContractAddress, tokens, duration)
                    .send({
                      from: this.userAddr,
                      gasLimit: exGasLimit,
                      gasPrice: exGasPrice,
                    });

                  transaction.on('error', (error, _receipt) => {
                    handleTxn(TxnState.Error(error));
                  });

                  transaction.on('transactionHash', (txnHash) => {
                    handleTxn(TxnState.Pending(txnHash));
                  });

                  transaction.on('receipt', (receipt) => {
                    handleTxn(TxnState.Success(receipt));
                  });
                });
              })
              .catch((error) => {
                handleTxn(TxnState.Error(error));
              });
          })
          .catch((error) => {
            handleTxn(TxnState.Error(error));
          });
      } else {
        handleTxn(TxnState.Error({ message }));
      }
    }
  };

  doGassedUnstake = (tokens, handleTxn) => {
    if (this.userAddr) {
      const lWeb3Instance = this.getWeb3(true);

      const message = null;
      if (!message) {
        const contract = this.getStakeContract(true);
        contract.methods
          .unstakeBatch(tokens)
          .estimateGas({ from: this.userAddr })
          .then((rawGaslimit) => {
            const exGasLimit = getExaggeratedLimitInHex(rawGaslimit);

            lWeb3Instance.eth.getGasPrice().then((rawGasPrice) => {
              const exGasPrice = getExaggeratedLimitInHex(rawGasPrice);

              const transaction = contract.methods.unstakeBatch(tokens).send({
                from: this.userAddr,
                gasLimit: exGasLimit,
                gasPrice: exGasPrice,
              });

              transaction.on('error', (error, _receipt) => {
                handleTxn(TxnState.Error(error));
              });

              transaction.on('transactionHash', (txnHash) => {
                handleTxn(TxnState.Pending(txnHash));
              });

              transaction.on('receipt', (receipt) => {
                handleTxn(TxnState.Success(receipt));
              });
            });
          })
          .catch((error) => {
            handleTxn(TxnState.Error(error));
          });
      } else {
        handleTxn(TxnState.Error({ message }));
      }
    }
  };

  doBiconomyStake = (stakeContractAddress, tokens, duration, handleTxn) => {
    if (this.userAddr) {
      const kWeb3Instance = this.getWeb3(true);

      // const message = this.isTransactionValid(10);
      const message = null;

      if (!message) {
        this.setApprovalWithBiconomy(
          stakeContractAddress,
          kWeb3Instance,
          handleTxn
        )
          .then(() => {
            this.getBiconomyInstance()
              .then((biconomyInstance) => {
                const bWeb3Instance = new Web3(biconomyInstance);
                bWeb3Instance.eth.transactionBlockTimeout = 200;
                const lWeb3Instance = this.getWeb3(true);

                const contract = new bWeb3Instance.eth.Contract(
                  StakingContractJSON.abi,
                  process.env.REACT_APP_CONTRACT_ADDRESS_STAKE
                );

                contract.methods
                  .getNonce(this.userAddr)
                  .call()
                  .then((nonce) => {
                    const fSignature = contract.methods
                      .stakeBatch(stakeContractAddress, tokens, duration)
                      .encodeABI();

                    const dataToSign = getDataToSign(
                      this.userAddr,
                      nonce,
                      fSignature
                    );

                    signTransaction(this.userAddr, dataToSign)
                      .then(({ r, s, v }) => {
                        contract.methods
                          .executeMetaTransaction(
                            this.userAddr,
                            fSignature,
                            r,
                            s,
                            v
                          )
                          .estimateGas({ from: this.userAddr })
                          .then((rawGasLimit) => {
                            const exGasLimit =
                              getExaggeratedLimitInHex(rawGasLimit);

                            lWeb3Instance.eth
                              .getGasPrice()
                              .then((rawGasPrice) => {
                                const exGasPrice =
                                  getExaggeratedLimitInHex(rawGasPrice);

                                const txn = contract.methods
                                  .executeMetaTransaction(
                                    this.userAddr,
                                    fSignature,
                                    r,
                                    s,
                                    v
                                  )
                                  .send({
                                    from: this.userAddr,
                                    gasLimit: exGasLimit,
                                    gasPrice: exGasPrice,
                                  });

                                txn
                                  .on('sending', () => {
                                    handleTxn(TxnState.Pending());
                                  })
                                  .on('error', (err, _receipt) => {
                                    handleTxn(TxnState.Error(err));
                                  })
                                  .on('transactionHash', (txnHash) => {
                                    handleTxn(TxnState.Pending(txnHash));
                                  })
                                  .on('receipt', (receipt) => {
                                    handleTxn(TxnState.Success(receipt));
                                  });
                              });
                          })
                          .catch((error) => {
                            handleTxn(TxnState.Error(error));
                          });
                      })
                      .catch((error) => {
                        handleTxn(TxnState.Error(error));
                      });
                  })
                  .catch((error) => {
                    handleTxn(TxnState.Error(error));
                  });
              })
              .catch((error) => {
                handleTxn(TxnState.Error(error));
              });
          })
          .catch((error) => {
            handleTxn(TxnState.Error(error));
          });
      } else {
        handleTxn(TxnState.Error({ message }));
      }
    }
  };

  doBiconomyUnstake = (tokens, handleTxn) => {
    this.getBiconomyInstance()
      .then((biconomyInstance) => {
        const bWeb3Instance = new Web3(biconomyInstance);
        bWeb3Instance.eth.transactionBlockTimeout = 200;
        const lWeb3Instance = this.getWeb3(true);

        const contract = new bWeb3Instance.eth.Contract(
          StakingContractJSON.abi,
          process.env.REACT_APP_CONTRACT_ADDRESS_STAKE
        );
        contract.methods
          .getNonce(this.userAddr)
          .call()
          .then((nonce) => {
            const fSignature = contract.methods
              .unstakeBatch(tokens)
              .encodeABI();

            const dataToSign = getDataToSign(this.userAddr, nonce, fSignature);

            signTransaction(this.userAddr, dataToSign)
              .then(({ r, s, v }) => {
                contract.methods
                  .executeMetaTransaction(this.userAddr, fSignature, r, s, v)
                  .estimateGas({ from: this.userAddr })
                  .then((rawGasLimit) => {
                    const exGasLimit = getExaggeratedLimitInHex(rawGasLimit);

                    lWeb3Instance.eth.getGasPrice().then((rawGasPrice) => {
                      const exGasPrice = getExaggeratedLimitInHex(rawGasPrice);

                      const txn = contract.methods
                        .executeMetaTransaction(
                          this.userAddr,
                          fSignature,
                          r,
                          s,
                          v
                        )
                        .send({
                          from: this.userAddr,
                          gasLimit: exGasLimit,
                          gasPrice: exGasPrice,
                        });

                      txn
                        .on('sending', () => {
                          handleTxn(TxnState.Pending());
                        })
                        .on('error', (err, _receipt) => {
                          handleTxn(TxnState.Error(err));
                        })
                        .on('transactionHash', (txnHash) => {
                          handleTxn(TxnState.Pending(txnHash));
                        })
                        .on('receipt', (receipt) => {
                          handleTxn(TxnState.Success(receipt));
                        });
                    });
                  })
                  .catch((error) => {
                    handleTxn(TxnState.Error(error));
                  });
              })
              .catch((error) => {
                handleTxn(TxnState.Error(error));
              });
          })
          .catch((error) => {
            handleTxn(TxnState.Error(error));
          });
      })
      .catch((error) => {
        handleTxn(TxnState.Error(error));
      });
  };

  doGassedClaimReward = (tokens, handleTxn) => {
    if (this.userAddr) {
      const lWeb3Instance = this.getWeb3(true);

      const message = null;
      if (!message) {
        const contract = this.getStakeContract(true);
        contract.methods
          .claimTokensRewards(tokens)
          .estimateGas({ from: this.userAddr })
          .then((rawGaslimit) => {
            const exGasLimit = getExaggeratedLimitInHex(rawGaslimit);

            lWeb3Instance.eth.getGasPrice().then((rawGasPrice) => {
              const exGasPrice = getExaggeratedLimitInHex(rawGasPrice);

              const transaction = contract.methods.claimTokensRewards(tokens).send({
                from: this.userAddr,
                gasLimit: exGasLimit,
                gasPrice: exGasPrice,
              });

              transaction.on('error', (error, _receipt) => {
                handleTxn(TxnState.Error(error));
              });

              transaction.on('transactionHash', (txnHash) => {
                handleTxn(TxnState.Pending(txnHash));
              });

              transaction.on('receipt', (receipt) => {
                handleTxn(TxnState.Success(receipt));
              });
            });
          })
          .catch((error) => {
            handleTxn(TxnState.Error(error));
          });
      } else {
        handleTxn(TxnState.Error({ message }));
      }
    }
  };

  doBiconomyClaimReward = (tokens, handleTxn) => {
    this.getBiconomyInstance()
      .then((biconomyInstance) => {
        const bWeb3Instance = new Web3(biconomyInstance);
        bWeb3Instance.eth.transactionBlockTimeout = 200;
        const lWeb3Instance = this.getWeb3(true);

        const contract = new bWeb3Instance.eth.Contract(
          StakingContractJSON.abi,
          process.env.REACT_APP_CONTRACT_ADDRESS_STAKE
        );
        contract.methods
          .getNonce(this.userAddr)
          .call()
          .then((nonce) => {
            const fSignature = contract.methods
              .claimTokensRewards(tokens)
              .encodeABI();

            const dataToSign = getDataToSign(this.userAddr, nonce, fSignature);

            signTransaction(this.userAddr, dataToSign)
              .then(({ r, s, v }) => {
                contract.methods
                  .executeMetaTransaction(this.userAddr, fSignature, r, s, v)
                  .estimateGas({ from: this.userAddr })
                  .then((rawGasLimit) => {
                    const exGasLimit = getExaggeratedLimitInHex(rawGasLimit);

                    lWeb3Instance.eth.getGasPrice().then((rawGasPrice) => {
                      const exGasPrice = getExaggeratedLimitInHex(rawGasPrice);

                      const txn = contract.methods
                        .executeMetaTransaction(
                          this.userAddr,
                          fSignature,
                          r,
                          s,
                          v
                        )
                        .send({
                          from: this.userAddr,
                          gasLimit: exGasLimit,
                          gasPrice: exGasPrice,
                        });

                      txn
                        .on('sending', () => {
                          handleTxn(TxnState.Pending());
                        })
                        .on('error', (err, _receipt) => {
                          handleTxn(TxnState.Error(err));
                        })
                        .on('transactionHash', (txnHash) => {
                          handleTxn(TxnState.Pending(txnHash));
                        })
                        .on('receipt', (receipt) => {
                          handleTxn(TxnState.Success(receipt));
                        });
                    });
                  })
                  .catch((error) => {
                    handleTxn(TxnState.Error(error));
                  });
              })
              .catch((error) => {
                handleTxn(TxnState.Error(error));
              });
          })
          .catch((error) => {
            handleTxn(TxnState.Error(error));
          });
      })
      .catch((error) => {
        handleTxn(TxnState.Error(error));
      });
  };

  getBiconomyInstance = () => {
    const shouldDebug = process.env.REACT_APP_ENV_TYPE === 'development';
    const biconomy = new Biconomy(window.ethereum, {
      apiKey: process.env.REACT_APP_BICONOMY_API_KEY,
      debug: shouldDebug,
    });

    if (this.userAddr) {
      const message = null;

      if (!message) {
        return isBiconomyReady(biconomy);
      }
    }
    return 'error';
  };
}
