/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import FontAwesome from 'react-fontawesome';
import LimitModal from './modals/LimitModal';

export default function StakeGallery({ nfts, onSelect, isLoading }) {
  const [selectedTokens, setSelectedTokens] = useState([]);
  const [actionLimit, setActionLimit] = useState(null);

  const getObject = (token) => nfts.find((x) => x.tokenId === token);

  const handleSelect = (e, isChecked, token) => {
    if (
      selectedTokens.length >= 20 &&
      !selectedTokens.includes(getObject(token))
    ) {
      e.target.checked = false;
      setActionLimit(true);
      return;
    }

    if (isChecked === true) {
      setSelectedTokens([getObject(token), ...selectedTokens]);
    } else {
      setSelectedTokens(selectedTokens.filter((i) => i.tokenId !== token));
    }
  };

  const selectAll = (tokens) => {
    const images = document.getElementById('gallery').querySelectorAll('input');
    images.forEach((element, index) => {
      if (index <= 19) {
        element.checked = true;
      }
    });
    setSelectedTokens(
      tokens.slice(0, 20).map(({ tokenId }) => getObject(tokenId))
    );
  };

  const deselectAll = () => {
    const images = document.getElementById('gallery').querySelectorAll('input');
    images.forEach((element) => {
      element.checked = false;
    });
    setSelectedTokens([]);
  };

  useEffect(() => {
    onSelect(selectedTokens);
  }, [selectedTokens]);

  useEffect(() => {
    setSelectedTokens([]);
    deselectAll();
  }, [nfts]);

  const handleLimitClose = () => {
    setActionLimit(null);
  };

  return (
    <StyledDiv>
      {nfts.length > 0 ? (
        <div className='counter'>
          <p className='total-count'>Total {nfts.length} Items</p>
          <div className='selected-wrapper'>
            <p className='selected-count'>
              {selectedTokens.length > 0 && `${selectedTokens.length} selected`}
            </p>
            {selectedTokens.length <= 0 ? (
              <button type='button' onClick={() => selectAll(nfts)}>
                {nfts.length < 20 ? 'Select All' : 'Select 20 nfts'}
              </button>
            ) : (
              <button type='button' onClick={() => deselectAll()}>
                Deselect All
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className='no-data'>
          {isLoading === false && <p>This collection is empty.</p>}
        </div>
      )}
      <Container id='gallery'>
        {nfts.map((nft) => (
          <div className='image' key={nft.tokenId}>
            <input
              type='checkbox'
              id={`nft-image-${nft.tokenId}`}
              name={nft.tokenId}
              value={nft.tokenId}
              onClick={(e) => handleSelect(e, e.target.checked, e.target.value)}
              hidden
            />

            <label htmlFor={`nft-image-${nft.tokenId}`}>
              {nft.isVideo ? (
                <video autoPlay muted loop>
                  <source src={nft.imageUrl} type='video/mp4' />
                </video>
              ) : (
                <img src={nft.imageUrl} alt='' />
              )}
            </label>

            <div className='img-badge'>
              {nft.openSeaUrl && (
                <div className='img-badge-link'>
                  <a href={nft.openSeaUrl} target='_blank' rel='noreferrer'>
                    <FontAwesome name='link' size='lg' />
                  </a>
                </div>
              )}
              <div className='img-badge-token'>Token {nft.tokenId}</div>
            </div>
          </div>
        ))}
      </Container>
      <LimitModal
        showModal={actionLimit != null}
        handleClose={() => handleLimitClose()}
      />
    </StyledDiv>
  );
}

const StyledDiv = styled(Container)`
  position: relative;
  max-width: 998px;
  width: 998px;
  background: #efebe5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 30px;

  @media only screen and (max-width: 576px) {
    padding: 15px;
  }

  @media only screen and (max-width: 992px) {
    width: 85vw;
  }

  .counter {
    font-weight: bolder;
    display: flex;
    justify-content: space-between;
  }

  .selected-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;

    button {
      font-weight: bolder;
      box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
      border: none;
      border-radius: 10px;
      padding: 0.5rem;
      transition: all 100ms ease-in;
    }
    button:hover {
      background-color: rgb(221 220 220);
    }
    button:active {
      box-shadow: none;
    }
  }

  .no-data {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-family: 'Caveat Brush', Sans-serif;
  }

  & > .container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-auto-flow: row;
    width: 100%;
    max-height: 424px;
    min-height: 424px;
    overflow-y: auto;
    padding-right: 10px;

    @media only screen and (max-width: 992px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: 576px) {
      grid-template-columns: repeat(1, 1fr);
      max-height: 400px;
      min-height: 400px;
    }
  }

  .container::-webkit-scrollbar {
    width: 4px;
  }

  .container::-webkit-scrollbar-track {
    background-color: #c4c4c4;
  }

  .container::-webkit-scrollbar-thumb {
    background-color: #a1a0a0;
    border-radius: 8px;
  }

  .image {
    position: relative;
    height: fit-content;

    img,
    video {
      box-sizing: border-box;
      border-radius: 8px;
      width: 100%;
      min-height: 235px;
      height: 100%;
      transition: all 100ms ease-in;
      background-color: #ddd;
      border: 0px solid #549aff;
    }

    video {
      object-fit: cover;
    }

    input:checked + label > img {
      border: 6px solid #549aff;
      transition: all 100ms ease-in;
    }

    input:checked + label > video {
      border: 6px solid #549aff;
      transition: all 100ms ease-in;
    }

    .img-badge {
      position: absolute;
      display: flex;
      left: 15px;
      bottom: 15px;
      color: #fff;

      @media only screen and (max-width: 768px) {
        display: flex;
      }

      .img-badge-token,
      .img-badge-link {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #549aff;
        padding: 5px;
        border-radius: 10px;
      }

      .img-badge-token {
        font-size: 14px;
        font-weight: bolder;
        background: none;
        margin-left: 5px;
        text-shadow: 0px 2px 4px rgb(0, 0, 0);
      }

      .img-badge-link a {
        font-size: 12px;
        color: white;
      }
    }
  }

  .image:hover > .img-badge {
    display: flex;
  }
`;
