import React, { useState } from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import { getCollectionById } from '../models/collections';

export default function CollectionPicker({ list, onChange, initialSelection }) {
  const initialCollection = getCollectionById(initialSelection);
  const [isListOpen, setIsListOpen] = useState(false);
  const [headerTitle, setHeaderTitle] = useState(
    initialCollection?.name
  );
  const [headerImg, setHeaderImg] = useState('');

  const toggleList = () => {
    setIsListOpen(!isListOpen);
  };

  const selectItem = (item) => {
    setHeaderTitle(item.name);
    setIsListOpen(false);
    setHeaderImg(item.img);
    onChange(item.id);
  };

  return (
    <StyledDiv>
      <button type='button' className='dd-header' onClick={toggleList}>
        <div className='dd-header-icon'>
          {isListOpen ? (
            <FontAwesome name='angle-up' size='lg' />
          ) : (
            <FontAwesome name='angle-down' size='lg' />
          )}
        </div>
        <div className='dd-item-content'>
          <img src={headerImg} alt='' className='dd-item-img' />
          <div className='dd-item-title'>{headerTitle}</div>
        </div>
        <div />
      </button>

      {isListOpen && (
        <div role='list' className='dd-list'>
          {list.map((item) => (
            <button
              type='button'
              className='dd-list-item'
              key={item.id}
              onClick={() => selectItem(item)}
            >
              <div className='dd-item-content'>
                <img src={item.img} alt='' className='dd-item-img' />
                <div className='dd-item-title'>{item.name}</div>
              </div>
            </button>
          ))}
        </div>
      )}
    </StyledDiv>
  );
}

const StyledDiv = styled(Container)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  max-width: 500px;
  min-width: 250px;
  width: 90vw;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  button {
    height: 40px;
    border-radius: 8px;
    outline: none;
    border: none;
  }
  .dd-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #A876D9;
    font-family: 'Caveat Brush', Sans-serif;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: all 300ms ease-in;

    .dd-header-icon{
      position: absolute;
      width: 20px;
      height: 20px;
      line-height:20px;
      background: #006100;
      color: #fff;
      border-radius: 50%;
      left: 10px;
    }
  }

  .dd-header:hover {
    background: #8e64b7;
  }

  .dd-item-content{
    display: flex;
    align-items: center;

    .dd-item-img {
      width: 26px;
      hieght: 26px;
      margin: 0px 10px;
      object-fit: cover;
      border-radius: 50%;
    }

    .dd-item-title {
      font-family: 'Caveat Brush', Sans-serif;
      font-weight: 500;
      font-size: 18px;
      color: #fff;
    }
  }

  .dd-list {
    position: absolute;
    top:40px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    background: #DCDCDC;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 999;

    .dd-list-item {
      display: flex;
      justify-content: center;
      align-items: center;
      background:none;
      border-bottom: 0.4px solid rgba(0, 0, 0, 0.35);
      border-radius: 0px;
      transition: all 100ms ease-in;


      .dd-item-title {
        color: #000;
      }
    }

    .dd-list-item: hover{
      background: rgba(0,0,0,0.1);
    }
    .dd-list-item:last-child {
      border-bottom: none;
    }
  }
  }
`;
