/* eslint-disable no-console */
import { tryParseJsonRPCError } from '../utils/type-utils';

const isUnParsableError = (error) => {
  if (error.toString().includes('TypeError')) {
    return true;
  }
  return false;
};

const isEVMError = (error) => {
  if (error.toString().includes('Transaction has been reverted by the EVM:')) {
    return true;
  }
  return false;
};

export default class ModalError {
  constructor(message, code, hash) {
    this.message = message;
    this.code = code;
    this.txnHash = hash;
  }

  static fromError = (error) => {
    let message = 'Unknown Error';
    let code = 500;
    let hash = '';

    if (isUnParsableError(error)) {
      message = 'Unable to process your request, Try again';
    } else if (isEVMError(error)) {
      message = 'Failed To Process Transaction';
      hash = error.receipt.transactionHash;
    } else {
      const parsedMessage = tryParseJsonRPCError(error);
      message =
        parsedMessage?.message ??
        parsedMessage?.details ??
        error?.message ??
        error?.toString() ??
        'Unknown Error';

      if (message.includes('Internal') || message.includes('header')) {
        message =
          'Failed to communicate with Blockchain network, Close and try again';
      }
      code = error.code ?? 0;
    }

    return new ModalError(message, code, hash);
  };
}
