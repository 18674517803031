import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export default function StyledButton({
  onClick,
  disabled,
  text,
  size = 'med',
}) {
  return (
    <StyledDiv size={size}>
      <Button variant='primary' disabled={disabled} onClick={() => onClick()}>
        {text}
      </Button>
    </StyledDiv>
  );
}

const sizedPadding = (props) => {
  if (props.size) {
    if (props.size === 'lrg') {
      return { top: '1rem', bottom: '1rem', left: '2rem', right: '2rem' };
    }
    if (props.size === 'med') {
      return {
        top: '0.75rem',
        bottom: '0.75rem',
        left: '1.5rem',
        right: '1.5rem',
      };
    }
  }
  return {
    top: '0.375rem',
    bottom: '0.375rem',
    left: '0.75rem',
    right: '0.75rem',
  };
};

const sizedFontSize = (props) => {
  if (props.size) {
    if (props.size === 'lrg') {
      return '2.188rem';
    }
    if (props.size === 'med') {
      return '1rem';
    }
  }
  return '1rem';
};

const StyledDiv = styled.div`
  .btn {
    line-height: 1;
    padding-left: ${(props) => sizedPadding(props).left};
    padding-right: ${(props) => sizedPadding(props).right};
    padding-top: ${(props) => sizedPadding(props).top};
    padding-bottom: ${(props) => sizedPadding(props).bottom};
    border-radius: 8px;
    border: 0px solid transparent;
    transition: all 0.3s;
    font-family: 'Caveat Brush', Sans-serif;
    font-size: ${(props) => sizedFontSize(props)};
    font-weight: 500;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .btn-primary {
    color: #ffffff;
    background-color: #009e00;
  }
  .btn-primary:hover {
    color: #ffffff;
    background: #006100;
    border-color: transparent;
  }
  .btn-primary:focus {
    color: #ffffff;
    background: #006100;
    outline: 2px solid #1e73be !important;
    border-color: transparent;
    box-shadow: none;
  }
  .btn-primary:active {
    box-shadow: none;
  }
  .btn-primary:disabled {
    background: gray;
  }
`;
