import React from 'react';
import { Container, Modal } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import styled from 'styled-components';
import Spinner from '../Spinner';

export default function ApprovalModal({ showModal, state }) {
  return (
    showModal && (
      <ModalWrapper>
        <Container>
          <Modal.Title>Please Wait</Modal.Title>
          <p>You will have two transactions</p>
          <ul>
            <li>
              <span>1. Sets Approval for staking</span>
              {state === 'pending' ? (
                <Spinner size='20px' />
              ) : state === 'success' ? (
                <FontAwesome name='check-circle' size='lg' />
              ) : (
                ''
              )}
            </li>
            <li>
              <span>2. Staking selected NFTs</span>
              {state === 'success' && <Spinner size='20px' />}
            </li>
          </ul>
          <p>Note: You only need to do this once for each collection</p>
        </Container>
      </ModalWrapper>
    )
  );
}

const ModalWrapper = styled(Container)`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 9998;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);

  body{
    overflow: hidden;
  }

  .h4 {
    font-size: 1.5rem;
  }

  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    background: #5e3c7f;
    color: white;
    box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;
    border-radius: 8px;
    padding: 30px;
    align-items: center;
    width: 400px;
    font-family: 'Caveat Brush', Sans-serif;
    margin: 20px;
  }

  ul {
    padding: 0px;
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 5px;
      padding: 10px 15px;
      background-color: rgba(0,0,0,0.15);
      border-radius: 10px;
      margin-bottom: 10px;
    }
  }
`;
