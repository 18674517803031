import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ModalError from '../../models/modal-error';
import stores from '../../stores';
import ErrorModal from '../modals/ErrorModal';
import StyledButton from './StyledButton';

const WalletButton = observer(() => {
  const { walletStore } = stores;

  const [isLoading, setIsLoading] = useState(false);
  const [errorModal, setErrorModal] = useState(null);

  const { ethereum } = window;

  useEffect(() => {
    walletStore.checkIfMetamaskInstalled();
  });

  const handleOnConnect = (connectionInfo) => {
    setIsLoading(true);
    walletStore
      .onConnect(connectionInfo)
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    ethereum?.on('connect', handleOnConnect);
    return () => {
      setIsLoading(false);
      ethereum?.removeListener('chainChanged', handleOnConnect);
    };
  }, []);

  useEffect(() => {
    ethereum?.on('accountsChanged', walletStore.onAccountsChanged);
    ethereum?.on('chainChanged', walletStore.onChainChanged);
    return () => {
      ethereum?.removeListener(
        'accountsChanged',
        walletStore.onAccountsChanged
      );
      ethereum?.removeListener('chainChanged', walletStore.onChainChanged);
    };
  });

  const onConnectWallet = () => {
    setIsLoading(true);
    walletStore
      .connectToWallet()
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorModal(ModalError.fromError(error));
      });
  };

  const formattedPublicAddress = (address) => {
    if (!address) return 'Connect to wallet'.toUpperCase();
    return (
      <span>
        {address.substring(0, 5)}
        ....
        {address.substring(address.length - 4)}
      </span>
    );
  };

  const handleClose = () => {
    setErrorModal(null);
    window.location.reload();
  };

  return (
    walletStore.isWalletInstalled && (
      <StyledDiv>
        <StyledButton
          disabled={isLoading}
          onClick={() => onConnectWallet()}
          text={
            isLoading
              ? 'Connecting...'
              : formattedPublicAddress(walletStore.userAccount)
          }
        />

        <ErrorModal
          handleClose={() => handleClose()}
          message={errorModal?.message ?? ''}
          showModal={errorModal != null}
        />
      </StyledDiv>
    )
  );
});

export default WalletButton;

const StyledDiv = styled.div`
  & > div:not(.container){
    position: absolute;
    width: 95vw;
    margin: 10px;
    display: flex;
    justify-content: end;
  }
  @media only screen and (max-width: 576px) {
    & > div:not(.container){
      position: static;
      display: flex;
      justify-content: center;
      margin: 10px 0px;
    }
  }
`;
