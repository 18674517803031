const parseBool = (value) => {
  if (value && typeof value === 'string') {
    return value === 'true';
  }
  return false;
};

const tryParseJsonRPCError = (error) => {
  try {
    // eslint-disable-next-line no-unused-vars
    const sI = error.message.indexOf('{');
    const parsedMessage = JSON.parse(error.message.slice(sI).replace('\n', ''));
    return parsedMessage;
  } catch (err) {
    return null;
  }
};

export { parseBool, tryParseJsonRPCError };
