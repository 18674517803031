import { makeAutoObservable } from 'mobx';
import BlockChainParams from '../../models/block-chain-params';

const { ethereum } = window;

export default class WalletStore {
  rootStore;

  constructor(rootStore) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
    // check for metamask installation after a delay of 100ms
    setTimeout(() => {
      this.checkIfMetamaskInstalled();
    }, 100);
  }

  _chainId = BlockChainParams.chainId;

  get chainId() {
    return this._chainId;
  }

  set chainId(value) {
    this._chainId = value;
  }

  _isMetamaskInstalled = false;

  get isWalletInstalled() {
    return this._isMetamaskInstalled;
  }

  set isWalletInstalled(value) {
    this._isMetamaskInstalled = value;
  }

  _userAccount = null;

  get userAccount() {
    return this._userAccount;
  }

  set userAccount(value) {
    this._userAccount = value;
  }

  onConnect = async ({ chainId }) => {
    if (ethereum?.isConnected()) {
      const accounts = await ethereum.request({
        method: 'eth_requestAccounts',
      });

      this.onAccountsChanged(accounts);
      this.onChainChanged(chainId);
    }
  };

  onAccountsChanged = (accounts) => {
    if (accounts && accounts.length > 0) {
      [this.userAccount] = accounts;
    } else {
      this.userAccount = null;
    }
  };

  onChainChanged = (newChainId) => {
    this.chainId = newChainId;
  };

  connectToWallet = async () => {
    if (this._isMetamaskInstalled) {
      try {
        // Try to select/add chain to wallet
        await ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [BlockChainParams],
        });
      } catch (addError) {
        // eslint-disable-next-line no-console
        console.log('wallet_addEthereumChain', addError);
        throw addError;
      }

      // Login/Connection request
      const accounts = await ethereum.request({
        method: 'eth_requestAccounts',
      });

      /*
       * metamask in future will support multiple accounts.
       * This is a future implementation, currently only single account is provided
       * this account is available in [0] index.
       */
      [this.userAccount] = accounts;
    } else {
      // eslint-disable-next-line no-console
      console.log('Cannot connect to wallet, MetamaskNotInstalled');
    }
  };

  checkIfMetamaskInstalled = () => {
    if (typeof ethereum !== 'undefined') {
      // Some wallet is installed
      if (ethereum.isMetaMask) {
        // Installed wallet is metamask
        this.isWalletInstalled = true;
      }
    } else {
      this.isWalletInstalled = false;
    }
  };
}
