import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal, Container } from 'react-bootstrap';
import styled from 'styled-components';
import BlockChainParams from '../../models/block-chain-params';
import FormattedHash from '../FormattedHash';
import StyledButton from '../buttons/StyledButton';

export default function SuccessModal({
  showModal,
  handleClose,
  txnHash,
  title,
}) {
  return (
    showModal && (
      <ModalWrapper>
        <Container>
          <Modal.Title>{title}</Modal.Title>
          <p>Check your wallet in some time !</p>
          {txnHash && <FormattedHash txnHash={txnHash} />}
          {txnHash && (
            <Button
              variant='outline-primary'
              href={`${BlockChainParams.blockExplorerUrls}tx/${txnHash}`}
              target='_blank'
              rel='noreferrer'
              className='link'
            >
              View transaction in Block-Explorer
            </Button>
          )}
          <br />
          <StyledButton
            text='Close'
            variant='secondary'
            onClick={handleClose}
          />
        </Container>
      </ModalWrapper>
    )
  );
}

const ModalWrapper = styled(Container)`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 9998;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);

  .h4 {
    font-size: 1.5rem;
  }

  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    background: #5e3c7f;
    color: white;
    box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;
    border-radius: 8px;
    padding: 30px;
    align-items: center;
    width: 400px;
    font-family: 'Caveat Brush', Sans-serif;
    margin: 20px;


    a {
      color: burlywood;
    }
  }
`;

SuccessModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  txnHash: PropTypes.string,
  title: PropTypes.string.isRequired,
};

SuccessModal.defaultProps = {
  txnHash: '',
};
