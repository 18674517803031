import React from 'react';
import styled from 'styled-components';
import Spinner from './Spinner';

export default function LoadingScreen() {
  return (
    <StyledDiv>
      <div className='divLoader'>
        <Spinner size='50px' />
      </div>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  .divLoader {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9999;
  }
`;
