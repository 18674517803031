const setCollectionState = (collectionID) => {
  localStorage.setItem('currentCollection', collectionID);
};

const getCollectionState = () => {
  const state = localStorage.getItem('currentCollection');
  if (state) {
    return state;
  }
  localStorage.setItem('currentCollection', '1');
  return '1';
};

export { setCollectionState, getCollectionState };
