import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import stores from '../stores';
import blockChainParams from '../models/block-chain-params';
import ModalError from '../models/modal-error';
import ErrorModal from './modals/ErrorModal';

const Balance = observer(() => {
  const [currentBalance, seCurrentBalance] = useState('');
  const [errorModal, setErrorModal] = useState(null);
  const { walletStore, contractStore } = stores;

  const fetchBalance = () => {
    contractStore.getBeetsBalance().then((balance) => {
      seCurrentBalance((balance / 1e18).toFixed(2));
    }).catch((error) => {
      setErrorModal(ModalError.fromError(error));
    });
  };

  const checkNetworkReady = () =>
    (walletStore.userAccount != null) && (walletStore.chainId === blockChainParams.chainId);

  useEffect(() => {
    if (checkNetworkReady()) {
      fetchBalance();
    }
  }, [walletStore.userAccount, walletStore.chainId]);

  const handleClose = () => {
    setErrorModal(null);
    window.location.reload();
  };

  return (
    checkNetworkReady() && (
    <Wrapper>
      <div className='balance'>
        <div className='label'>Balance :</div>
        <div className='value'>{currentBalance}</div>
        <div className='currency'>BEETS</div>
      </div>

      <ErrorModal
        handleClose={() => handleClose()}
        message={errorModal?.message ?? ''}
        showModal={errorModal != null}
      />
    </Wrapper>
    )
  );
});

export default Balance;

const Wrapper = styled.div`
  overflow: hidden;
  color: white;
  background-color: #2f4f4f8f;
  font-family: 'Caveat Brush', Sans-serif;
  border-radius: 9px;

  .balance {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 100%;
    box-sizing: border-box;
    padding: 9px;
    justify-content: center;
    column-gap: 10px;
  }

  .label {
  }

  .value {
    font-size: 1.3rem;
  }

  .currency {
    font-size: 1.3rem;
  }
`;
