const durations = [
  {
    label: 'Daily',
    time: 86400,
    value: 'daily',
  },
  {
    label: 'Minutes',
    time: 600,
    value: 'minutes',
    dropped: true,
  },
  {
    label: 'Weekly',
    time: 604800,
    value: 'weekly',
    dropped: true,
  },
  {
    label: 'Monthly',
    time: 2592000,
    value: 'monthly',
  },
  {
    label: 'Quarterly',
    time: 7776000,
    value: 'quarterly',
  },
  {
    label: 'Semi Annually',
    time: 15552000,
    value: 'semiAnnually',
  },
];

export const getDurationByValue = (value) =>
  durations.find((x) => x.value === value);

export const getDurationByTime = (time) =>
  durations.find((x) => x.time > time - 100 && x.time < time + 100);

export default durations;
