import React from 'react';
import { Alert } from 'react-bootstrap';
import styled from 'styled-components';

export default function StyledAlert({ text }) {
  return (
    <StyledDiv className='alert-container'>
      <Alert variant='warning'>{text}</Alert>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  align-self: center;
  margin: auto;
  width: 500px;
  font-family: 'Caveat Brush', Sans-serif;
  
  @media only screen and (max-width: 600px) {
    width: auto;
  }
  
  .alert {
    position: relative;
    top: 5rem;
    text-align: center;
    font-size: 1.15rem;
    color: #046c04;
    background: #efebe5;
    padding: 15px 0px;
    box-shadow: 0px 0px 23px rgb(0 0 0 / 40%);
    margin: 20px;
  }
`;
