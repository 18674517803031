import Web3, { utils } from 'web3';
import BlockChainParams from '../models/block-chain-params';

const { ethereum } = window;

const domainType = (oldMethod) => [
  { name: 'name', type: 'string' },
  { name: 'version', type: 'string' },
  ...(oldMethod
    ? [
      { name: 'chainId', type: 'uint256' },
      { name: 'verifyingContract', type: 'address' },
    ]
    : [
      { name: 'verifyingContract', type: 'address' },
      { name: 'salt', type: 'bytes32' },
    ]),
];

const metaTransactionType = [
  { name: 'nonce', type: 'uint256' },
  { name: 'from', type: 'address' },
  { name: 'functionSignature', type: 'bytes' },
];

const domainData = (oldMethod, collectionObj) => {
  if (oldMethod && collectionObj) {
    return {
      name: collectionObj.bicoDomainName,
      version: '1',
      chainId: parseInt(BlockChainParams.chainId, 16),
      verifyingContract: collectionObj.contractAddress,
    };
  }
  if (collectionObj) {
    return {
      name: collectionObj.bicoDomainName,
      version: '1',
      verifyingContract: collectionObj.contractAddress,
      salt: utils.padLeft(BlockChainParams.chainId, 64),
    };
  }
  return {
    name: process.env.REACT_APP_BICONOMY_DOMAIN_NAME_STAKE,
    version: '1',
    verifyingContract: process.env.REACT_APP_CONTRACT_ADDRESS_STAKE,
    salt: utils.padLeft(BlockChainParams.chainId, 64),
  };
};

const getSignatureParameters = (signature, web3Utils) => {
  const r = signature.slice(0, 66);
  const s = '0x'.concat(signature.slice(66, 130));
  let v = '0x'.concat(signature.slice(130, 132));
  v = web3Utils.hexToNumber(v);
  if (![27, 28].includes(v)) v += 27;
  return {
    r,
    s,
    v,
  };
};

const getDataToSign = (
  userAddr,
  nonce,
  functionSignature,
  oldMethod,
  collectionObj
) => {
  const message = {
    nonce: parseInt(nonce, 10),
    from: userAddr,
    functionSignature,
  };

  const dataToSign = JSON.stringify({
    types: {
      EIP712Domain: domainType(oldMethod),
      MetaTransaction: metaTransactionType,
    },
    domain: domainData(oldMethod, collectionObj),
    primaryType: 'MetaTransaction',
    message,
  });
  return dataToSign;
};

const signTransaction = (userAddr, dataToSign) =>
  new Promise((resolve, reject) => {
    const web3Utils = new Web3(ethereum).utils;
    ethereum.sendAsync(
      {
        jsonrpc: '2.0',
        id: 999999999999,
        method: 'eth_signTypedData_v4',
        params: [userAddr, dataToSign],
      },
      (error, response) => {
        if (error) {
          reject(error);
        } else {
          const signature = response.result;
          if (!web3Utils.isHexStrict(signature)) {
            reject(Error(`Given value ${signature} is not a valid hex string`));
          } else {
            resolve(getSignatureParameters(signature, web3Utils));
          }
        }
      }
    );
  });

const isBiconomyReady = (biconomy) =>
  new Promise((resolve, reject) => {
    biconomy
      .onEvent('biconomy_ready', () => resolve(biconomy))
      .onEvent('biconomy_error', (error, _errMsg) => reject(Error(error)));
  });

const getExaggeratedLimitInHex = (value) => {
  const web3Utils = new Web3(ethereum).utils;

  const exaggeratedGasLimit = web3Utils
    .toBN(value)
    .div(web3Utils.toBN(1))
    .add(web3Utils.toBN(value));

  const exaggeratedGasLimitInHex = web3Utils.toHex(exaggeratedGasLimit);

  return exaggeratedGasLimitInHex;
};

export {
  getDataToSign,
  signTransaction,
  isBiconomyReady,
  getExaggeratedLimitInHex,
};
