/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import {
  setCollectionState,
  getCollectionState,
} from '../utils/collection-state';

import blockChainParams from '../models/block-chain-params';
import ModalError from '../models/modal-error';
import stores from '../stores';
import CollectionPicker from '../components/CollectionPicker';
import UnstakeGallery from '../components/UnstakeGallery';
import VioletButton from '../components/buttons/VioletButton';
import StyledButton from '../components/buttons/StyledButton';
import ConfirmUnstakeDialogue from '../components/confirmDialogues/ConfirmUnstakeDialogue';
import StyledAlert from '../components/StyledAlert';
import NftData from '../models/nft-data';
import collections from '../models/collections';
import Balance from '../components/Balance';

import ErrorModal from '../components/modals/ErrorModal';
import RelayModal from '../components/modals/RelayModal';
import SuccessModal from '../components/modals/SuccessModal';
import LoadingScreen from '../components/LoadingScreen';

const Unstake = observer(() => {
  const { walletStore, contractStore } = stores;
  const [prefetchedAllToken, setPrefetchedAllToken] = useState([]);
  const [currentNfts, setCurrentNfts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [actionConfirm, setActionConfirm] = useState(null);
  const [tokenSelected, setTokenSelected] = useState([]);
  const [collectionSelected, setCollectionSelected] = useState(
    getCollectionState()
  );
  const [reward, setReward] = useState(0);
  const [successTitle, setSuccessTitle] = useState('Unstaking Successful');

  const [actionError, setActionError] = useState(null);
  const [actionSuccess, setActionSuccess] = useState(null);
  const [actionPending, setActionPending] = useState(null);

  const handleCollectionChange = (selected) => {
    setCurrentNfts(
      prefetchedAllToken.filter(({ collectionId }) => collectionId === selected)
    );
    setCollectionSelected(selected);
    setCollectionState(selected);
  };

  const fetchTokens = () => {
    if (walletStore.userAccount === null) return;
    setIsLoading(true);

    const collectionDetails = collections.map(async (item) => {
      const arr = await contractStore.getStakedTokens(item.contractAddress);
      return { id: item.id, tokens: arr };
    });

    Promise.all(collectionDetails)
      .then((cols) => {
        const tokenPromises = [];
        cols.forEach((col) => {
          col.tokens.forEach((token) => {
            tokenPromises.push(
              NftData.fromMetaUrl(token.tokenId, token.metaUrl, col.id)
            );
          });
        });

        Promise.all(tokenPromises).then((results) => {
          setPrefetchedAllToken(results);
          setIsLoading(false);
        });
      })
      .catch((error) => {
        setActionError(ModalError.fromError(error));
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchTokens();
  }, [walletStore.userAccount]);

  useEffect(() => {
    handleCollectionChange(collectionSelected);
  }, [prefetchedAllToken]);

  const fetchInitData = () => {};

  const handleErrorClose = () => {
    setActionError(null);
    window.location.reload();
  };
  const handleSuccessClose = () => {
    setActionSuccess(null);
    setIsLoading(true);
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  };

  const handlePendingClose = () => {
    setActionPending(null);
    fetchInitData();
  };

  const handleUnstake = (tokens) => {
    setIsLoading(true);
    setSuccessTitle('Unstaking Successful');
    contractStore.doUnstake(tokens, (txnState) => {
      txnState.match({
        Error: (error) => {
          setActionPending(null);
          setActionSuccess(null);
          setIsLoading(false);
          setActionError(ModalError.fromError(error));
        },
        Pending: (transactionHash) => {
          setActionSuccess(null);
          setActionError(null);
          setIsLoading(false);
          setActionPending({ transactionHash });
        },
        Success: ({ transactionHash }) => {
          setActionPending(null);
          setActionError(null);
          setIsLoading(false);
          setActionSuccess({ transactionHash });
        },
      });
    });
  };

  const handleConfirmOpen = () => {
    const tokens = tokenSelected.map((token) => token.tokenId);
    contractStore.getRewardinfo(tokens).then((result) => {
      setReward((result / 1e18).toFixed(2));
    });
    setActionConfirm(true);
  };

  const handleConfirmClose = () => {
    setActionConfirm(null);
  };

  const handleConfirm = () => {
    const tokens = tokenSelected.map((token) => token.tokenId);
    handleUnstake(tokens);
    handleConfirmClose();
  };

  const handleClaim = () => {
    setSuccessTitle('Claim Successful');
    const tokens = tokenSelected.map((token) => token.tokenId);
    setIsLoading(true);
    setActionConfirm(null);
    contractStore.doClaimReward(tokens, (txnState) => {
      txnState.match({
        Error: (error) => {
          setActionPending(null);
          setActionSuccess(null);
          setIsLoading(false);
          setActionError(ModalError.fromError(error));
        },
        Pending: (transactionHash) => {
          setActionSuccess(null);
          setActionError(null);
          setIsLoading(false);
          setActionPending({ transactionHash });
        },
        Success: ({ transactionHash }) => {
          setActionPending(null);
          setActionError(null);
          setIsLoading(false);
          setActionSuccess({ transactionHash });
        },
      });
    });
  };

  const navigate = useNavigate();

  return !walletStore.isWalletInstalled ? (
    <StyledAlert text='MetaMask is not installed' />
  ) : !walletStore.userAccount ? (
    <StyledAlert text='Wallet not connected' />
  ) : walletStore.chainId !== blockChainParams.chainId ? (
    <StyledAlert text={`You’re not on the ${blockChainParams.chainName}`} />
  ) : (
    <StyledDiv>
      <Balance />
      <CollectionPicker
        list={collections}
        onChange={handleCollectionChange}
        initialSelection={collectionSelected}
      />
      <UnstakeGallery
        nfts={currentNfts}
        onSelect={setTokenSelected}
        isLoading={isLoading}
      />

      <div className='btn-group'>
        <StyledButton text='Go Back' onClick={() => navigate('/')} />
        <VioletButton
          text='Claim / Unstake'
          onClick={() => handleConfirmOpen()}
          disabled={tokenSelected.length <= 0}
        />
      </div>

      <ConfirmUnstakeDialogue
        showModal={actionConfirm != null}
        handleClose={() => handleConfirmClose()}
        handleConfirm={handleConfirm}
        handleClaim={handleClaim}
        selectedNfts={tokenSelected}
        selectedCollection={collectionSelected}
        reward={reward}
      />
      <ErrorModal
        showModal={actionError != null}
        handleClose={() => handleErrorClose()}
        message={actionError?.message}
      />
      <SuccessModal
        showModal={actionSuccess != null}
        handleClose={() => handleSuccessClose()}
        txnHash={actionSuccess?.transactionHash}
        title={successTitle}
      />
      <RelayModal
        showModal={actionPending != null}
        handleClose={() => handlePendingClose()}
        txnHash={actionPending?.transactionHash}
      />

      {isLoading && <LoadingScreen />}
    </StyledDiv>
  );
});

export default Unstake;

const StyledDiv = styled(Container)`
  font-family: 'Roboto', serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  row-gap: 10px;

  @media only screen and (max-width: 576px) {
    height: auto;
  }

  .btn-group {
    display: flex;
    column-gap: 10px;

    @media only screen and (max-width: 576px) {
      position: fixed;
      bottom: 20px;
    }
  }
`;
