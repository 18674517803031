import React from 'react';
import styled from 'styled-components';
import StyledButton from './StyledButton';

export default function VioletButton({
  onClick,
  disabled,
  text,
  size,
}) {
  return (
    <StyledDiv>
      <StyledButton text={text} disabled={disabled} size={size} onClick={() => onClick()} />
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  .btn-primary {
    background-color: #A876D9;
  }
  .btn-primary:hover {
    background: #6d4395;
  }
  .btn-primary:focus {
    background: #6d4395;
  }

`;
