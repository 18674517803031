function FormattedHash({ txnHash }) {
  return (
    <p className='formatted-txnhash'>
      {' '}
      Transaction Hash: {txnHash.substring(0, 5)}
      ....
      {txnHash.substring(txnHash.length - 4)}
    </p>
  );
}

export default FormattedHash;
