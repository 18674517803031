import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Stake from './pages/Stake';
import Unstake from './pages/Unstake';
import './App.css';
import WalletButton from './components/buttons/WalletButton';

function App() {
  return (
    <div className='App'>
      <WalletButton />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/stake' element={<Stake />} />
          <Route path='/unstake' element={<Unstake />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
