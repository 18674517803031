/* eslint-disable max-len */
import { observer } from 'mobx-react-lite';
import { Container, Navbar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';
import FontAwesome from 'react-fontawesome';
import StyledButton from '../components/buttons/StyledButton';
import RedButton from '../components/buttons/RedButton';
import blockChainParams from '../models/block-chain-params';
import stores from '../stores';
import Balance from '../components/Balance';

const StakeOrConnect = observer(() => {
  const { walletStore } = stores;
  const navigate = useNavigate();
  return !walletStore.isWalletInstalled ? (
    <p className='home-wallet-err'>
      <FontAwesome name='exclamation' />
      MetaMask is not installed
    </p>
  ) : !walletStore.userAccount ? (
    <p className='home-wallet-err'>
      <FontAwesome name='exclamation' />
      Wallet not connected
    </p>
  ) : walletStore.chainId !== blockChainParams.chainId ? (
    <p className='home-wallet-err'>
      <FontAwesome name='exclamation' />
      {`You’re not on the ${blockChainParams.chainName}`}{' '}
    </p>
  ) : (
    <div className='actions'>
      <StyledButton text='Stake' onClick={() => navigate('/stake')} />
      <RedButton text='Unstake' onClick={() => navigate('/unstake')} />
    </div>
  );
});

export default function Home() {
  return (
    <StyledDiv>
      <Navbar expand='lg'>
        <Container>
          <div className='schroot-logo-text'>Schroot Beets</div>
          <Balance />
        </Container>
      </Navbar>

      <Container className='instruction'>
        <h2 className='heading'>Welcome to Schroot Family Farm!</h2>
        <p className='content'>
          Sixty digital acres with access to the best looking BEETS on the web.
          Stake your <b> Schroots, Schroot Loot, and Shellfurds </b> to work
          alongside Mose to harvest BEETS tokens. BEETS are the heart-beet of
          the Schroot ecosystem. There`s a reason we don`t call it heart-celery.
          Stake as many or as few as you like, selecting one of three possible
          time frames. You can unstake at any time but if unstaking occurs
          before the end of the selected time frame, Mose will keep your accrued
          BEETS tokens. He doesn`t take kindly to quitters.
        </p>
        <p className='moto'>
          <b>BEETS: The Alpha Jackhammer Token.</b>
        </p>
        <StakeOrConnect />
      </Container>
    </StyledDiv>
  );
}

const StyledDiv = styled(Container)`
  font-family: 'Caveat Brush', Sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  @media only screen and (max-width: 576px) {
    height: auto;
  }

  .navbar {
    @media only screen and (max-width: 576px) {
    }
  }

  .schroot-logo-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.1;
    color: #ffffff;
    font-family: 'Caveat Brush', Sans-serif;
    font-size: 96px;
    font-weight: 400;
    text-shadow: 5px 7px 4px rgb(0 0 0 / 53%);
    @media only screen and (max-width: 600px) {
      font-size: 40px;
      margin-bottom: 10px;
    }
  }

  .instruction {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 998px;
    background: #efebe5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 40px;
    margin: 10px;

    .heading {
      font-family: Roboto;
      color: #000000;
      margin: 0;
    }

    .content {
      font-family: Roboto;
      color: #000000;
      font-size: 18px;
      line-height: 27px;
      text-indent: 17px;
      text-align: justify;
    }

    .moto {
      font-size: 1.1rem;
      font-family: 'Roboto';
    }

    .home-wallet-err {
      font-size: 1.3rem;
      background-color: #9b5e5e30;
      padding: 10px 15px;
      border-radius: 10px;
      display: flex;
      column-gap: 10px;
      align-items: end;
    }

    .actions {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      width: 100%;
      column-gap: 20px;

      button {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
      }
    }
  }
`;
