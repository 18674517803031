import React from 'react';
import styled from 'styled-components';

export default function Spinner({ size }) {
  return (
    <StyledDiv size={size}>
      <svg className='spinner' viewBox='0 0 50 50'>
        <circle
          className='path'
          cx='25'
          cy='25'
          r='20'
          fill='none'
          strokeWidth='5'
        />
      </svg>
    </StyledDiv>
  );
}

const getSize = (props) => props.size;

const StyledDiv = styled.div`
  display: flex; 
  .spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    top: 50%;
    left: 50%;
    width: ${(props) => getSize(props)};
    height: ${(props) => getSize(props)};
    
    & .path {
      stroke: white;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
    
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
`;
