/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import {
  setCollectionState,
  getCollectionState,
} from '../utils/collection-state';

import blockChainParams from '../models/block-chain-params';
import ModalError from '../models/modal-error';
import ErrorModal from '../components/modals/ErrorModal';
import RelayModal from '../components/modals/RelayModal';
import SuccessModal from '../components/modals/SuccessModal';
import ApprovalModal from '../components/modals/ApprovalModal';

import stores from '../stores';
import CollectionPicker from '../components/CollectionPicker';
import StakeGallery from '../components/StakeGallery';
import VioletButton from '../components/buttons/VioletButton';
import StyledButton from '../components/buttons/StyledButton';
import ConfirmStakeDialogue from '../components/confirmDialogues/ConfirmStakeDialogue';
import StyledAlert from '../components/StyledAlert';
import LoadingScreen from '../components/LoadingScreen';
import NftData from '../models/nft-data';
import collections, { getCollectionById } from '../models/collections';
import CollectionStore from '../stores/collection/collection-store';
import Balance from '../components/Balance';

const Stake = observer(() => {
  const { walletStore, contractStore } = stores;

  const [prefetchedAllToken, setPrefetchedAllToken] = useState([]);
  const [currentNfts, setCurrentNfts] = useState([]);
  const [tokenSelected, setTokenSelected] = useState([]);
  const [collectionSelected, setCollectionSelected] = useState(
    getCollectionState()
  );

  const [isLoading, setIsLoading] = useState(false);
  const [actionConfirm, setActionConfirm] = useState(null);
  const [actionError, setActionError] = useState(null);
  const [actionSuccess, setActionSuccess] = useState(null);
  const [actionPending, setActionPending] = useState(null);
  const [actionApproval, setActionApproval] = useState(null);

  const handleCollectionChange = (selected) => {
    setCurrentNfts(
      prefetchedAllToken.filter(({ collectionId }) => collectionId === selected)
    );
    setCollectionSelected(selected);
    setCollectionState(selected);
  };

  const fetchTokens = () => {
    if (walletStore.userAccount === null) return;
    setIsLoading(true);
    const collectionDetails = collections.map(async (item) => {
      const instance = new CollectionStore(
        walletStore,
        item.contractAddress,
        item.abi
      );

      const arr = await instance.getTokensOfOwner();
      return { id: item.id, tokens: arr };
    });

    Promise.all(collectionDetails)
      .then((cols) => {
        const tokenPromises = [];

        cols.forEach((col) => {
          const colObj = collections.find((x) => x.id === col.id);
          col.tokens.forEach((token) => {
            tokenPromises.push(NftData.fromTokens(token, colObj));
          });
        });

        Promise.all(tokenPromises).then((results) => {
          setPrefetchedAllToken(results);
          setIsLoading(false);
        });
      })
      .catch((error) => {
        setActionError(ModalError.fromError(error));
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchTokens();
  }, [walletStore.userAccount]);

  useEffect(() => {
    handleCollectionChange(collectionSelected);
  }, [prefetchedAllToken]);

  const fetchInitData = () => {};

  const handleErrorClose = () => {
    setActionError(null);
    window.location.reload();
  };
  const handleSuccessClose = () => {
    setActionSuccess(null);
    setIsLoading(true);

    setTimeout(() => {
      fetchTokens();
    }, 3000);
  };

  const handlePendingClose = () => {
    setActionPending(null);
    fetchInitData();
  };

  // useEffect(() => fetchInitData(), [contractStore.userAddr]);

  const handleStake = (contractAddress, tokens, duration) => {
    setIsLoading(true);
    contractStore.doStake(contractAddress, tokens, duration, (txnState) => {
      txnState.match({
        Error: (error) => {
          setActionPending(null);
          setActionSuccess(null);
          setActionApproval(null);
          setIsLoading(false);
          setActionError(ModalError.fromError(error));
        },
        Pending: (transactionHash) => {
          setActionSuccess(null);
          setActionError(null);
          setActionApproval(null);
          setIsLoading(false);
          setActionPending({ transactionHash });
        },
        Success: ({ transactionHash }) => {
          setActionPending(null);
          setActionError(null);
          setActionApproval(null);
          setIsLoading(false);
          setActionSuccess({ transactionHash });
        },
        Approval: (state) => {
          setActionPending(null);
          setActionError(null);
          setActionSuccess(null);
          setIsLoading(false);
          setActionApproval(state);
        },
      });
    });
  };

  const handleConfirmClose = () => {
    setActionConfirm(null);
  };

  const handleConfirm = (duration) => {
    const tokens = tokenSelected.map((token) => token.tokenId);
    const { contractAddress } = getCollectionById(collectionSelected);
    handleStake(contractAddress, tokens, duration);
    handleConfirmClose();
  };

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };

  return !walletStore.isWalletInstalled ? (
    <StyledAlert text='MetaMask is not installed' />
  ) : !walletStore.userAccount ? (
    <StyledAlert text='Wallet not connected' />
  ) : walletStore.chainId !== blockChainParams.chainId ? (
    <StyledAlert text={`You’re not on the ${blockChainParams.chainName}`} />
  ) : (
    <StyledDiv>
      <Balance />
      <CollectionPicker
        list={collections}
        onChange={handleCollectionChange}
        initialSelection={collectionSelected}
      />
      <StakeGallery
        nfts={currentNfts}
        onSelect={setTokenSelected}
        isLoading={isLoading}
      />
      <div className='btn-group'>
        <StyledButton text='Go Back' onClick={() => handleGoBack()} />
        <VioletButton
          text='Stake'
          onClick={() => setActionConfirm(true)}
          disabled={tokenSelected.length <= 0}
        />
      </div>

      { actionConfirm != null && (
      <ConfirmStakeDialogue
        handleClose={() => handleConfirmClose()}
        handleConfirm={handleConfirm}
        selectedNfts={tokenSelected}
        selectedCollection={collectionSelected}
      />
      )}
      <ErrorModal
        showModal={actionError != null}
        handleClose={() => handleErrorClose()}
        message={actionError?.message}
      />
      <SuccessModal
        showModal={actionSuccess != null}
        handleClose={() => handleSuccessClose()}
        txnHash={actionSuccess?.transactionHash}
        title='Staking Successful'
      />
      <RelayModal
        showModal={actionPending != null}
        handleClose={() => handlePendingClose()}
        txnHash={actionPending?.transactionHash}
      />
      <ApprovalModal
        showModal={actionApproval != null}
        state={actionApproval}
      />
      {isLoading && <LoadingScreen />}
    </StyledDiv>
  );
});

export default Stake;

const StyledDiv = styled(Container)`
  font-family: 'Roboto', serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  row-gap: 10px;

  @media only screen and (max-width: 576px) {
    height: auto;
  }

  .btn-group {
    display: flex;
    column-gap: 10px;

    @media only screen and (max-width: 576px) {
      position: fixed;
      bottom: 20px;
    }
  }
`;
