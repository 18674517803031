const union = (types) =>
  types.reduce(
    (prev, type) => ({
      ...prev,
      [type]: (data) => ({
        match: (fns) => fns[type](data),
      }),
    }),
    {}
  );

const TxnState = union(['Error', 'Pending', 'Success', 'Approval']);

export default TxnState;
