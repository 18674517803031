import PropTypes from 'prop-types';
import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import StyledButton from '../buttons/StyledButton';

const cleanMessage = (message = '') => {
  const limit = 300;
  if (message && message.length >= limit) {
    return `${message.substring(0, limit)}...`;
  }
  return message;
};

export default function ErrorModal({ showModal, handleClose, message }) {
  return (
    showModal && (
      <ModalWrapper>
        <Container>
          <h4>Request Failed</h4>
          {message && <p>{cleanMessage(message)}</p>}
          <StyledButton text='Close' onClick={handleClose} />
        </Container>
      </ModalWrapper>
    )
  );
}

const ModalWrapper = styled(Container)`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 9998;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);

  .h4 {
    font-size: 1.5rem;
  }

  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    background: #701414;
    color: white;
    box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;
    border-radius: 8px;
    padding: 30px;
    align-items: center;
    width: 400px;
    font-family: 'Caveat Brush', Sans-serif;
    margin: 20px;

  }
`;

ErrorModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string,
};

ErrorModal.defaultProps = {
  message: '',
};
