import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import FontAwesome from 'react-fontawesome';
import VioletButton from '../buttons/VioletButton';
import StyledButton from '../buttons/StyledButton';
import { getCollectionById } from '../../models/collections';

export default function ConfirmUnstakeDialogue({
  showModal,
  handleClose,
  handleConfirm,
  handleClaim,
  selectedNfts,
  selectedCollection,
  reward,
}) {
  return (
    showModal && (
      <ModalWrapper>
        <Container>
          <div className='modal-img'>
            {selectedNfts.map((nft) => (
              nft.isVideo ? (
                <video autoPlay muted key={nft.tokenId}>
                  <source src={nft.imageUrl} type='video/mp4' />
                </video>
              ) : <img src={nft.imageUrl} alt={nft.tokenId} key={nft.tokenId} />
            ))}
          </div>
          <div className='modal-content'>
            <div>
              <h3 className='title'>
                {getCollectionById(selectedCollection)?.name}
              </h3>
              <p>
                {selectedNfts.length} {selectedNfts.length > 1 ? 'NFTs' : 'NFT'}{' '}
                selected
              </p>
            </div>
            <p className='reward-wrapper'>
              <span className='label'>Reward:</span>
              <span className='reward-amount'>{reward} BEETS</span>
            </p>
            <div className='modal-btn-group'>
              <StyledButton text='Claim' disabled={reward <= 0} onClick={() => handleClaim()} />
              <VioletButton text='Unstake' onClick={() => handleConfirm()} />
            </div>
          </div>
          <button
            className='close-btn'
            type='button'
            onClick={() => handleClose()}
          >
            <FontAwesome name='close' size='lg' />
          </button>
        </Container>
      </ModalWrapper>
    )
  );
}

const ModalWrapper = styled(Container)`
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  top: 0px;

  .container {
    position: relative;
    display: flex;
    background: #efebe5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 30px;
    column-gap: 20px;

    @media only screen and (max-width: 576px) {
      flex-direction: column;
    }

    .modal-content {
      .title {
        margin-top: 0px;
      }

      .reward-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        
        .label {
          font-size: 0.8rem;
        }

        .reward-amount {
          font-size: 1rem;
          font-weight: 800;
        }
      }
      
      .modal-btn-group {
        display: flex;
        column-gap: 10px;
      }
    }

    .modal-img {
      width: 175px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      height: 180px;
      overflow-y: auto;
      padding-right: 5px;

      img {
        width: 100%;
        border-radius: 8px;
      }
    }

    .modal-img::-webkit-scrollbar {
      width: 4px;
    }

    .modal-img::-webkit-scrollbar-track {
      background-color: #c4c4c4;
    }

    .modal-img::-webkit-scrollbar-thumb {
      background-color: #a1a0a0;
      border-radius: 8px;
    }

    .close-btn {
      position: absolute;
      right: 10px;
      top: 10px;
      background: none;
      border: none;
      color: gray;
      transition: all 200ms ease-in;
    }

    .close-btn:hover {
      color: black;
    }
  }
`;
