import PropTypes from 'prop-types';
import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import StyledButton from '../buttons/StyledButton';

export default function LimitModal({ showModal, handleClose }) {
  return (
    showModal && (
      <ModalWrapper>
        <Container>
          <h4>You cannot select more than 20 tokens at a time</h4>
          <StyledButton text='Close' onClick={handleClose} />
        </Container>
      </ModalWrapper>
    )
  );
}

const ModalWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 9998;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);

  .h4 {
    font-size: 1.5rem;
  }

  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    background: #701414;
    color: white;
    box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;
    border-radius: 8px;
    padding: 30px;
    align-items: center;
    width: 400px;
    font-family: 'Caveat Brush', Sans-serif;
    margin: 20px;

  }
`;

LimitModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
